<template>
  <div>
    <div class="grid crud-demo">
      <h6 class="">Administração de Comissões</h6>
      <Toast/>

      <div class="col-12">
        <div class="card widget-boxes  p-0 grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Ano</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Mês</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="60" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Semana</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="80" :showValue="false" class="mb-3"></ProgressBar>
          </div>
          <div class="col-12 md:col-6 lg:col-3 py-3 px-6">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-plus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Hoje</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>
            <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">



          <DataTable ref="dt" :value="comissoes" v-model:selection="selectedComissoes" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} comissões" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>

            <!--          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
            <Column field="code" header="Código" :sortable="true" filterField="code" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem">
              <template #body="slotProps">
                <span class="p-column-title">Code</span>
                {{slotProps.data.code}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>

            <Column field="representante" header="Representante" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Representante</span>
                {{slotProps.data.representante}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo representante"/>
              </template>
            </Column>


            <Column field="price" header="Valor" :sortable="true" class="text-right">
              <template #body="slotProps">
                <span class="p-column-title">Price</span>
                {{formatCurrency(slotProps.data.price)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>



            <Column field="inventoryStatus" header="Status" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'comissao-badge status-' + (slotProps.data.inventoryStatus ? slotProps.data.inventoryStatus.toLowerCase() : '')">{{slotProps.data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteComissao(slotProps.data)" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editComissao(slotProps.data)" />
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="comissaoDialog" :style="{width: '450px'}" header="Detalhes da Comissão" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/comissao/' + comissao.image" :alt="comissao.image" v-if="comissao.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="representante">Representante</label>
              <InputText id="representante" v-model.trim="comissao.representante" required="true" autofocus :class="{'p-invalid': submitted && !comissao.representante}" />
              <small class="p-invalid" v-if="submitted && !comissao.representante">Representante é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="comissao.description" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="comissao.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Select a Status">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.value">
                    <span :class="'comissao-badge status-' +slotProps.value.value">{{slotProps.value.label}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.value">
                    <span :class="'comissao-badge status-' +slotProps.value.toLowerCase()">{{slotProps.value}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Categoria</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="comissao.category" />
                  <label for="category1">Grandes Negócios</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="comissao.category" />
                  <label for="category2">Residencial</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="comissao.category" />
                  <label for="category3">Rural</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category4" name="category" value="Fitness" v-model="comissao.category" />
                  <label for="category4">Comercial</label>
                </div>
              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="comissao.price" mode="currency" currency="BRL" locale="pt-BR" />
              </div>

            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveComissao" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteComissaoDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="comissao">Confirma a exclusão de <b>{{comissao.name}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteComissaoDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteComissao" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteComissoesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="comissao">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteComissoesDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedComissoes" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-12">
        <div class="card">
          <h6>Evolução Anual</h6>
          <Chart type="line" :data="lineData" :options="lineOptions"></Chart>
        </div>
      </div>

    </div>


  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import ComissaoService from '@/service/ComissaoService';


export default {

  data() {
    return {
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      comissoes: null,
      comissaoDialog: false,
      deleteComissaoDialog: false,
      deleteComissoesDialog: false,
      comissao: {},
      selectedComissoes: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'QUALIFICADO', value: 'qualificado'},
        {label: 'ANÁLISE', value: 'análise'},
        {label: 'DESQUALIFICADO', value: 'desqualificado'}
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      barData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', ],
        datasets: [
          {
            label: 'Elaboração',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: [65, 59, 80, 81, 56, 55, 40]
          },
          {
            label: 'Convertido',
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            data: [28, 48, 40, 19, 86, 27, 90]
          }
        ]
      },
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },



      lineData: {
        labels: ['Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro'],
        datasets: [
          {
            label: 'Categoria 01',
            data: [0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            tension: .4
          },
          {
            label: 'Categoria 02',
            data: [0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            tension: .4
          },
          {
            label: 'Categoria 03',
            data: [0, 0, 0, 0, 0, 0, 0],
            fill: false,
            backgroundColor: 'rgb(75,126,192)',
            borderColor: 'rgb(75,126,192)',
            tension: .4
          }
        ]
      },
      lineOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },

    }
  },
  comissaoService: null,
  created() {
    this.comissaoService = new ComissaoService();
    this.initFilters();
  },
  mounted() {

    this.loading1 = false

    // this.comissaoService.getComissoes().then((data) => {
    //   console.log('comissoesList mounted')
    //   this.loading1 = false
    //   this.comissoes = data
    // })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.comissao = {};
      this.submitted = false;
      this.comissaoDialog = true;
    },
    hideDialog() {
      this.comissaoDialog = false;
      this.submitted = false;
    },
    saveComissao() {
      this.submitted = true;
      if (this.comissao.representante.trim()) {
        if (this.comissao.id) {
          this.comissao.inventoryStatus = this.comissao.inventoryStatus.value ? this.comissao.inventoryStatus.value: this.comissao.inventoryStatus;
          this.comissoes[this.findIndexById(this.comissao.id)] = this.comissao;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Comissão Atualizada', life: 3000});
        }
        else {
          this.comissao.id = this.createId();
          this.comissao.code = this.createId();
          this.comissao.image = 'comissao-placeholder.svg';
          this.comissao.inventoryStatus = this.comissao.inventoryStatus ? this.comissao.inventoryStatus.value : 'ANÁLISE';
          this.comissoes.push(this.comissao);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Comissão Criada', life: 3000});
        }
        this.comissaoDialog = false;
        this.comissao = {};
      }
    },
    editComissao(comissao) {
      this.comissao = {...comissao};
      this.comissaoDialog = true;
    },
    confirmDeleteComissao(comissao) {
      this.comissao = comissao;
      this.deleteComissaoDialog = true;
    },
    deleteComissao() {
      this.comissoes = this.comissoes.filter(val => val.id !== this.comissao.id);
      this.deleteComissaoDialog = false;
      this.comissao = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Comissão Deletada', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.comissoes.length; i++) {
        if (this.comissoes[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteComissoesDialog = true;
    },
    deleteSelectedComissoes() {
      this.comissoes = this.comissoes.filter(val => !this.selectedComissoes.includes(val));
      this.deleteComissoesDialog = false;
      this.selectedComissoes = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Comissões Deletadas', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
